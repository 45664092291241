@import "src/styles/helpers";

.page {
  display: grid;
  min-height: 100vh;
  grid-template-columns: repeat(2, 1fr);

  @include media(tabletPortrait) {
    grid-template-columns: auto;
  }

  @include media(mobile) {
    grid-template-columns: auto;
  }
}

.aside {
  position: relative;
  padding: 64px 74px;
  background-color: var(--secondary-200);
  background-image: url('/media/images/loginPageBg.svg');
  background-size: 1183px;
  background-position: center top -170px;
  background-repeat: no-repeat;
  overflow: hidden;

  &__title {
    @include h2;
    color: var(--primary-500);
    margin-top: 71px;
    max-width: 470px;
  }

  &__image {
    position: absolute;
    top: 364px;
    left: 74px;
    width: 1042px;
    height: 741px;

    @include media(desktopBig) {
      left: 140px;
    }

    @include media(tabletLandscape) {
      left: 48px;
      width: 850px;
      height: 604px;
    }
  }

  @include media(desktopBig) {
    padding: 64px 140px;
  }

  @include media(tabletLandscape) {
    padding: 64px 48px;
  }

  @include media(tabletPortrait) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.content {
  z-index: 1;
  background-color: var(--white);
  padding: 32px 24px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  &__header {
    grid-area: header;
    display: none;

    @include media(tabletPortrait) {
      display: block;
    }

    @include media(mobile) {
      display: block;
    }
  }

  @include media(desktopBig) {
    padding: 64px 200px;
  }

  @include media(desktop) {
    padding: 64px 120px;
  }

  @include media(desktopSmall) {
    padding: 64px 96px;
  }

  @include media(tabletLandscape) {
    padding: 64px 48px;
  }

  @include media(tabletPortrait) {
    padding: 48px;
  }
}

.main {
  grid-area: main;
  display: grid;
  align-content: center;
}

.footer {
  grid-area: footer;
  display: grid;
  justify-content: center;
  gap: 32px;

  .languageSelect {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .copyright {
    @include bodySmall;
    color: var(--primary-200);
    text-transform: uppercase;
  }
}

.logo {
  width: 136px;
  height: 37px;

  @include media(mobile) {
    width: 90px;
    height: 24px;
  }

  svg, img {
    width: 100%;
    height: 100%;
  }
}
